import * as React from "react";

import {
  BrandContainer,
  StyledColumn,
  StyledHeading
} from "./style";


const PortfolioTickerItem = ({
  columnTitle,
  columnLogo
}) => {
  return (
    <StyledColumn>
      <StyledHeading>
        { columnTitle }
      </StyledHeading>
      <BrandContainer>
        <img src={ columnLogo } />
      </BrandContainer>
    </StyledColumn>
  )
}


export default PortfolioTickerItem;
