import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../theme/style-constants";


export const StyledSection = styled.section`
  width: 100%;
  padding: ${ PADDINGS.container.section.mobile };
  text-align: ${ props => props.textAlignment };
  background-color: ${ props => props.darkBackground ? COLORS.background.dark : `transparent` };
  color: ${ props => props.darkBackground ? COLORS.text.light : COLORS.text.black };

  @media (min-width: ${ SCREENS.laptop }) {
    padding: ${ PADDINGS.container.section.laptop };
  }
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.largeDesktop };
  width: 90%;
  margin: 0 auto;
  padding: ${ props => props.containerized ? PADDINGS.container.section.laptop : `0` };
  background-color: ${ props => props.darkBackground ? COLORS.background.dark : props.containerized ? COLORS.background.gray : `transparent` };
`;

export const StyledHeading = styled.h2`
  width: ${ props => props.textAlignment === "center" ? `90%` : `100%` };
  margin: ${ props => props.textAlignment === "center" ? `0 auto 2rem` : `0 0 2rem` };

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ props => props.textAlignment === "center" ? `70%` : `100%` };
  }
`;

export const StyledDescription = styled.p`
  max-width: calc(${ CONTAINERS.largeDesktop } / 2);
  width: ${ props => props.textAlignment === "center" ? `90%` : `100%` };
  margin: ${ props => props.textAlignment === "center" ? `0 auto 4rem` : `0 0 4rem` };

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ props => props.textAlignment === "center" ? `70%` : `100%` };
  }
`;
