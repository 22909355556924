import styled from "styled-components";

import {
  COLORS,
  SCREENS
} from "../../../../theme/style-constants";


export const StyledFormControl = styled.div`
  grid-area: ${ props => props.gridArea };
`;

export const StyledLabel = styled.label`
  font-weight: 700;
`;

export const StyledField = styled.select`
  background: transparent;
  width: calc(100% - 2.5rem);
  height: 50px;
  margin: 1rem 0 0;
  border: 1px solid ${ COLORS.border.orange };
  border-radius: 26px;
  padding: 0 1.25rem;
  font-weight: 100;
  outline: none;
  -webkit-appearance: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

export const StyledOption = styled.option`

`;
