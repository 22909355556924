import styled from "styled-components";

import {
  COLORS
} from "../../../theme/style-constants";


export const StyledColumn = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 10rem;
  min-height: 28rem;
  width: 10rem;
  border-right: 1px solid ${ COLORS.border.gray };
`;

export const StyledHeading = styled.span`
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: auto;
  white-space: nowrap;
  font-size: 1.15rem;
  font-weight: 600;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  will-change: transform;
`;

export const BrandContainer = styled.span`
  display: flex;
  flex: none;
  position: relative;
  height: 6rem;
  margin: 2rem 0 3rem;

  img {
    display: block;
    width: 80%;
    margin: auto;
    object-fit: contain;
    will-change: transform;
  }
`;
