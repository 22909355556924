import styled from "styled-components";
import { Field } from "formik";

import {
  COLORS
} from "../../../../theme/style-constants";


export const StyledFormControl = styled.div`
  grid-area: ${ props => props.gridArea };
`;

export const FieldContainer = styled.div`
`;

export const StyledField = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border: 1px solid ${ COLORS.border.orange };
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: ${ COLORS.text.dark };
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${ COLORS.text.orange };
    }
  }

  & ${ StyledField }:checked ~ ${ StyledCheckbox } {
    background-color: ${ COLORS.background.orange };
  }

  & ${ StyledField }:checked ~ ${ StyledCheckbox }:after {
    display: block;
  }

  & ${ StyledCheckbox }:after {
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
    border: solid ${ COLORS.background.light };
    border-width: 4px;
    border-radius: 50%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
