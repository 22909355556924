import * as React from "react";

import {
  Container
} from "./style";


const CallToAction = ({
  darkBackground,
  ButtonComponent
}) => {
  return (
    <Container>
      { ButtonComponent }
    </Container>
  )
}


export default CallToAction;
