import * as React from "react";
import { ErrorMessage, useField } from "formik";

import TextError from "../../Errors/TextError";

import {
  StyledField,
  StyledFormControl,
  StyledLabel
} from "./style";


const Input = ({
  label,
  gridArea,
  ...rest
}) => {
  const [field] = useField(rest);

  return (
    <StyledFormControl
      gridArea={ gridArea }
    >
      <StyledLabel htmlFor={ field.name }>{ label }</StyledLabel>
      <StyledField
        id={ field.name }
        { ...field }
        { ...rest }
        name={ field.name }
      />
      <ErrorMessage component={ TextError } name={ field.name } />
    </StyledFormControl>
  )
}


export default Input;
