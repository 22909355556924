import * as React from "react";
import { ErrorMessage, Field } from "formik";

import TextError from "../../Errors/TextError";

import {
  StyledField
} from "./style";


const OhNoHoney = ( props ) => {
  const {
    label,
    name,
    gridArea,
    ...rest
  } = props;

  return (
    <StyledField
      gridArea={ gridArea }
    >
      <label htmlFor={ name }>{ label }</label>
      <Field id={ name } name={ name } { ...rest } />
      <ErrorMessage component={ TextError } name={ name } />
    </StyledField>
  )
}


export default OhNoHoney;
