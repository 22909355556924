import * as React from "react";

import Boolean from "../Fields/Boolean";
import CheckboxGroup from "../Fields/CheckboxGroup";
import DatePicker from "../Fields/DatePicker";
import Input from "../Fields/Input";
import OhNoHoney from "../Fields/OhNoHoney";
import RadioGroup from "../Fields/RadioGroup";
import SelectGroup from "../Fields/SelectGroup";
import TextArea from "../Fields/TextArea";


const FormikControl = ({
  control,
  ...props
}) => {
  switch (control) {
    case 'honeypot':
      return <OhNoHoney { ...props } />;
    case 'input':
      return <Input { ...props } />;
    case 'textarea':
      return <TextArea { ...props } />;
    case 'date':
      return <DatePicker { ...props } />;
    case 'checkbox':
      return <CheckboxGroup { ...props } />;
    case 'radio':
      return <RadioGroup { ...props } />;
    case 'select':
      return <SelectGroup { ...props } />;
    case 'boolean':
      return <Boolean { ...props } />;
    default:
      return null;
  }
}


export default FormikControl;
