import * as React from "react";

import {
  Container,
  StyledDescription,
  StyledHeading,
  StyledSection
} from "./style";


const Section = ({
  sectionTitle,
  sectionDescription,
  containerized,
  darkBackground,
  textAlignment,
  isComponentFullLength,
  Component
}) => {
  return (
    <StyledSection
      darkBackground={ darkBackground }
      textAlignment={ textAlignment }
    >
      <Container
        containerized={ containerized }
        darkBackground={ darkBackground }
      >
        { sectionTitle &&
          <StyledHeading
            textAlignment={ textAlignment }
          >
            { sectionTitle }
          </StyledHeading>
        }
        { sectionDescription &&
          <StyledDescription
            textAlignment={ textAlignment }
          >
            { sectionDescription }
          </StyledDescription>
        }
        { !isComponentFullLength &&
          <>
            { Component }
          </>
        }
      </Container>
      { isComponentFullLength &&
        <>
          { Component }
        </>
      }
    </StyledSection>
  )
}


export default Section;
