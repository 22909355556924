import * as React from "react";
import { ErrorMessage, useField } from "formik";

import TextError from "../../Errors/TextError";

import {
  FieldContainer,
  OptionContainer,
  StyledCheckbox,
  StyledFormControl,
  StyledInput,
  StyledLabel,
  StyledOptionLabel
} from "./style";


const CheckboxGroup = ({
  label,
  gridArea,
  options,
  ...rest
}) => {
  const [field] = useField(rest);
  
  return (
    <StyledFormControl
      gridArea={ gridArea }
    >
      <StyledLabel>{ label }</StyledLabel>
      <FieldContainer
        id={ field.name }
        name={ field.name }
      >
        { options.map(option => {
          return (
            <OptionContainer
              key={ option.key }
            >
              <StyledOptionLabel htmlFor={ option.value }>
                <StyledInput
                  type="checkbox"
                  id={ option.value }
                  { ...field }
                  { ...rest }
                  name={ field.name }
                  value={ option.value }
                />
                <StyledCheckbox></StyledCheckbox>
                { option.key }
              </StyledOptionLabel>
            </OptionContainer>
          )
        })}
      </FieldContainer>
      <ErrorMessage component={ TextError } name={ field.name } />
    </StyledFormControl>
  )
}


export default CheckboxGroup;
