import * as React from "react";

import {
  StyledError
} from "./style";


const TextError = ({
  children
}) => {
  return (
    <StyledError>
      { children }
    </StyledError>
  )
}


export default TextError;
