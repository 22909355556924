import styled from "styled-components";

import {
  COLORS
} from "../../../theme/style-constants";


export const StyledLine = styled.div`
  width: 80px;
  height: 1px;
  margin-right: 9px;
  background-color: ${ props => props.darkBackground ? COLORS.border.light : COLORS.border.dark };
  transform: translate3d(0, 0, 0);
  transform-origin: 0% 50%;
  transition: all 0.4s ease-in-out;
`;

export const StyledArrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3.5px 0 3.5px 6.1px;
  border-color: transparent transparent transparent ${ props => props.darkBackground ? COLORS.border.light : COLORS.border.dark };
  transform: translate3d(0, 0, 0);
  transition: all 0.4s ease-in-out;
`;

export const StyledButton = styled.button`
  display: inline-block;
  position: relative;
  width: 200px;
  height: 50px;
  background: transparent;
  border: 1px solid ${ COLORS.border.orange };
  border-radius: 26px;
  color: ${ props => props.darkBackground ? COLORS.text.light : COLORS.text.dark };
  text-decoration: none;
  cursor: pointer;
  transition: color 0.4s ease-in-out;

  &:visited {
    color: ${ props => props.darkBackground ? COLORS.text.light : COLORS.text.dark };
  }

  &:hover {
    color: ${ COLORS.text.orange };
    
    ${ StyledLine } {
      background-color: ${ COLORS.border.orange };
      transform: translate3d(0, 0, 0) scale(0.625, 1);
    }

    ${ StyledArrow } {
      border-color: transparent transparent transparent ${ COLORS.border.orange };
      transform: translate3d(-40px, 0, 0);
    }
  }
`;

export const StyledButtonText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.1rem;
`;

export const StyledIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
  width: 100px;
  height: 100%;
  margin-left: 80%;
  margin-top: -2px;
  overflow-x: hidden;
`;
