import styled from "styled-components";


export const StyledField = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1000;
  grid-area: ${ props => props.gridArea };
`;
