import * as React from "react";

import {
  LogoContainer,
  StyledColumn
} from "./style";


const MarketingPartnersTickerItem = ({
  logo
}) => {
  return (
    <StyledColumn>
      <LogoContainer>
        <img src={ logo } />
      </LogoContainer>
    </StyledColumn>
  )
}


export default MarketingPartnersTickerItem;
