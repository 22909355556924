import * as React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import FormikControl from "../FormikControl";

import SubmitButton from "../../Button/SubmitButton";

import {
  FormContainer,
  StyledFormGrid,
  StyledLink,
  StyledResponse
} from "./style";


const twilioMailFunctionURL = 'https://sunset-caiman-1110.twil.io/contact';


const ContactForm = () => {
  const initialValues = {
    name: '',
    email: '',
    nhsdhfvd: '',
    emjansdf: '',
    phjbsdfh: '',
    casdfdsf: '',
    rkjbskdj: [],
    dkhsdbfh: null,
    tsjdhfsf: '',
    mihbsdfs: '',
    mdknfken: '',
    akhshbhb: false
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string(),
    nhsdhfvd: Yup.string()
      .required('Required'),
    emjansdf: Yup.string()
      .required('Required')
      .email('Invalid email address.'),
    phjbsdfh: Yup.string()
      .required('Required'),
    casdfdsf: Yup.string(),
    rkjbskdj: Yup.array()
      .min(1, 'Please select at least one service.'),
    dkhsdbfh: Yup.string()
      .nullable(true)
      .required('Required'),
    tsjdhfsf: Yup.string()
      .required('Required'),
    mihbsdfs: Yup.string()
      .required('Required'),
    mdknfken: Yup.string()
      .required('Required'),
    akhshbhb: Yup.boolean()
      .required('Required')
      .oneOf([true], 'You must accept the Terms of Use and Privacy Policy.')
  });

  const onSubmit = async (e, { resetForm, setStatus }) => {
    const {
      name,
      email,
      nhsdhfvd,
      emjansdf,
      phjbsdfh,
      rkjbskdj,
      dkhsdbfh,
      tsjdhfsf,
      mihbsdfs,
      mdknfken,
      casdfdsf,
      akhshbhb
    } = e;

    const reasonForContact = rkjbskdj.join(', ');
    
    const response = await fetch(twilioMailFunctionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams({
        name,
        email,
        nhsdhfvd,
        emjansdf,
        phjbsdfh,
        casdfdsf,
        reasonForContact,
        dkhsdbfh,
        tsjdhfsf,
        mihbsdfs,
        mdknfken,
        akhshbhb
      }),
    });

    resetForm();

    if (response.status === 200) {
      gtag('event', 'submit', {
        'event_category': 'form'
      });
      
      setStatus({
        success: true
      });
    } else {
      setStatus({
        success: false
      });
    }
  };

  const reasonOptions = [
    { key: 'Brand Identity', value: 'Brand Identity' },
    { key: 'Content Creation', value: 'Content Creation' },
    { key: 'Website Design', value: 'Website Design' },
    { key: 'Digital Marketing', value: 'Digital Marketing' },
    { key: 'Other', value: 'Other' },
  ];

  const timeOptions = [
    { key: 'Select A Time...', value: '' },
    { key: '10:00am', value: '10am' },
    { key: '11:00am', value: '11am' },
    { key: '12:00pm', value: '12pm' },
    { key: '1:00pm', value: '1pm' },
    { key: '2:00pm', value: '2pm' },
    { key: '3:00pm', value: '3pm' },
    { key: '4:00pm', value: '4pm' },
    { key: '5:00pm', value: '5pm' },
    { key: '6:00pm', value: '6pm' },
  ];

  const meetingOptions = [
    { key: 'Phone Call', value: 'Phone' },
    { key: 'Zoom Call', value: 'Zoom' },
    { key: 'In Person', value: 'IRL' },
  ];

  return (
    <FormContainer>
      <Formik
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onSubmit={ onSubmit }
      >
        { formik => (
          <Form
            id="contact-form"
          >
            <StyledFormGrid>
              <FormikControl
                control="honeypot"
                gridArea="name"
                name="name"
                type="text"
                placeholder="Enter your name"
              />
              <FormikControl
                control="honeypot"
                gridArea="email"
                name="email"
                type="email"
                placeholder="Enter your email address"
              />
              <FormikControl
                control="input"
                gridArea="nhsdhfvd"
                name="nhsdhfvd"
                type="text"
                label="Full Name"
                title="Full Name"
                placeholder="Enter your name"
              />
              <FormikControl
                control="input"
                label="Phone Number"
                gridArea="phjbsdfh"
                name="phjbsdfh"
                type="tel"
                title="Phone Number"
                placeholder="Enter your phone number"
              />
              <FormikControl
                control="input"
                label="Email"
                gridArea="emjansdf"
                name="emjansdf"
                type="email"
                title="Email Address"
                placeholder="Enter your email address"
              />
              <FormikControl
                control="input"
                label="Company Name"
                gridArea="casdfdsf"
                name="casdfdsf"
                type="text"
                title="Company Name"
                placeholder="Enter your company name"
              />
              <FormikControl
                control="checkbox"
                gridArea="rkjbskdj"
                name="rkjbskdj"
                label="Reason For Contact"
                title="Reason For Contact"
                options={ reasonOptions }
              />
              <FormikControl
                control="date"
                gridArea="dkhsdbfh"
                name="dkhsdbfh"
                label="Select A Date"
                title="Select A Date"
                placeholder="MM/DD/YYYY"
              />
              <FormikControl
                control="select"
                gridArea="tsjdhfsf"
                name="tsjdhfsf"
                label="Select A Time"
                title="Select A Time"
                options={ timeOptions }
              />
              <FormikControl
                control="radio"
                gridArea="mihbsdfs"
                name="mihbsdfs"
                label="Meeting Preference"
                title="Meeting Preference"
                options={ meetingOptions }
              />
              <FormikControl
                control="textarea"
                label="Project Details"
                gridArea="mdknfken"
                name="mdknfken"
                title="Project Details"
                placeholder="Enter the details of your project"
              />
              <FormikControl
                control="boolean"
                gridArea="akhshbhb"
                name="akhshbhb"
                title="Terms of Use and Privacy Policy Acceptance"
                value={ <>I accept the <StyledLink to="/legal/terms-of-service">Terms of Service</StyledLink> and <StyledLink to="/legal/privacy-policy">Privacy Policy</StyledLink>.</> }
              />
            </StyledFormGrid>
            <SubmitButton
              buttonText="Submit"
            />
            { formik.status &&
              <>
                { formik.status.success &&
                  <StyledResponse
                    textColor="#26702E"
                  >
                    Thank you for your submission, we'll get back to you as soon as we can.
                  </StyledResponse>
                }
                { !formik.status.success &&
                  <StyledResponse
                    textColor="#991B1B"
                  >
                    Something went wrong. Please contact us directly at <a href="mailto:hello@explore.agency">hello@explore.agency</a>.
                  </StyledResponse>
                }
              </>
            }
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}


export default ContactForm;
