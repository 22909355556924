import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import MarketingPartnersTickerItem from "../../domain/MarketingPartners/MarketingPartnersTickerItem";
import PortfolioTickerItem from "../../domain/Portfolio/PortfolioTickerItem";

import {
  StyledTicker,
  TickerWrapper
} from "./style";


const Ticker = ({
  template,
  itemWidth
}) => {
  const { marketingPartners, portfolio } = useStaticQuery(graphql`
    query {
      marketingPartners: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "marketing-partner"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              logo {
                publicURL
              }
            }
          }
        }
      }

      portfolio: allMdx(
        filter: {
          frontmatter: {
            displayPortfolio: {
              eq: true
            }
            template: {
              eq: "portfolio"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              brandImage {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const nodes = template === "portfolio" ? portfolio.edges : marketingPartners.edges;

  const nodeList = nodes.map((node) => {
    if (template === 'portfolio') {
      return (
        <PortfolioTickerItem
          columnTitle={ node.node.frontmatter.title }
          columnLogo={ node.node.frontmatter.brandImage.publicURL }
        />
      )
    } else if (template === 'marketing-partner') {
      return (
        <MarketingPartnersTickerItem
          logo={ node.node.frontmatter.logo.publicURL }
        />
      )
    }
  });

  return (
    <TickerWrapper>
      <StyledTicker
        itemWidth={ itemWidth }
        numItems={ nodeList.length }
      >
        { nodeList }
        { nodeList }
        { nodeList }
      </StyledTicker>
    </TickerWrapper>
  )
}


export default Ticker;
