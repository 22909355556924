import * as React from "react";

import CallToAction from "../components/CallToAction";
import ContactForm from "../components/Form/ContactForm";
import Layout from "../components/Layout";
import PageHero from "../components/Hero/PageHero";
import Section from "../components/Section";
import SEO from "../components/SEO";
import Ticker from "../components/Ticker";

import SecondaryButton from "../components/Button/SecondaryButton";


const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      
      <PageHero
        pageTitle="Ready To Explore? Let's Talk."
        pageDescription="If you have a project in mind, just want a chat, or you want to be part of Explore — get in touch!"
        Component={
          <ContactForm />
        }
      />

      <Section
        sectionTitle="Prefer To Get On A Call?"
        textAlignment="center"
        darkBackground
        Component={
          <CallToAction
            darkBackground
            ButtonComponent={
              <SecondaryButton
                isExternal
                linkLocation="tel:+14164560428"
                linkText="Call Now"
                darkBackground
              />
            }
          />
        }
      />

      <Section
        Component={
          <Ticker
            template="marketing-partner"
            itemWidth={ 16 }
          />
        }
      />
    </Layout>
  )
}


export default ContactPage;
