import * as React from "react";
import { ErrorMessage, useField } from "formik";

import TextError from "../../Errors/TextError";

import {
  StyledField,
  StyledFormControl,
  StyledLabel,
  StyledOption
} from "./style";


const SelectGroup = ({
  label,
  gridArea,
  options,
  ...rest
}) => {
  const [field] = useField(rest);
  
  return (
    <StyledFormControl
      gridArea={ gridArea }
    >
      <StyledLabel htmlFor={ field.name }>{ label }</StyledLabel>
      <StyledField
        id={ field.name }
        { ...field }
        { ...rest }
        name={ field.name }
      >
        { options.map(option => {
          return (
            <StyledOption
              key={ option.key }
              value={ option.value }
            >
              { option.key }
            </StyledOption>
          )
        })}
      </StyledField>
      <ErrorMessage component={ TextError } name={ field.name } />
    </StyledFormControl>
  )
}


export default SelectGroup;
