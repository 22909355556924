import styled from "styled-components";

import {
  COLORS
} from "../../../theme/style-constants";


export const StyledColumn = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 16rem;
  min-height: 6rem;
  width: 16rem;
  border-right: 1px solid transparent;
`;

export const LogoContainer = styled.span`
  display: flex;
  flex: none;
  position: relative;
  width: 100%;
  height: 6rem;

  img {
    display: block;
    width: 60%;
    margin: auto;
    object-fit: contain;
    will-change: transform;
  }
`;
