import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS
} from "../../theme/style-constants";


export const Container = styled.div`
  max-width: ${ CONTAINERS.largeDesktop };
  width: 100%;
  margin: 0 auto;
`;
