import styled from "styled-components";

import {
  COLORS
} from "../../../../theme/style-constants";


export const StyledFormControl = styled.div`
  grid-area: ${ props => props.gridArea };
`;

export const StyledLabel = styled.label`
  font-weight: 700;
`;

export const StyledField = styled.textarea`
  background: transparent;
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid ${ COLORS.border.orange };
  border-radius: 0 !important;
  padding: 2rem 0 0;
  font-weight: 100;
  -webkit-appearance: none;
  outline: none;
  resize: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${ COLORS.text.black };
  }
`;
