import styled from "styled-components";
import { Link } from "gatsby";

import {
  SCREENS
} from "../../../theme/style-constants";


export const FormContainer = styled.div``;

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "name"
    "email"
    "nhsdhfvd"
    "phjbsdfh"
    "emjansdf"
    "casdfdsf"
    "rkjbskdj"
    "dkhsdbfh"
    "tsjdhfsf"
    "mihbsdfs"
    "mdknfken"
    "akhshbhb"
    "submit";
  row-gap: 2rem;
  width: 100%;

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name email email"
      "nhsdhfvd phjbsdfh emjansdf casdfdsf"
      "rkjbskdj rkjbskdj rkjbskdj rkjbskdj"
      "dkhsdbfh tsjdhfsf blank blank"
      "mihbsdfs mihbsdfs mihbsdfs mihbsdfs"
      "mdknfken mdknfken mdknfken mdknfken"
      "akhshbhb akhshbhb akhshbhb akhshbhb"
      "submit submit submit submit";
    column-gap: 2rem;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export const StyledResponse = styled.p`
  margin: 1rem 0 0;
  color: ${ props => props.textColor };
`;
