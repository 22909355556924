import * as React from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";

import TextError from "../../Errors/TextError";

import "react-datepicker/dist/react-datepicker.css";

import {
  StyledField,
  StyledFormControl,
  StyledLabel
} from "./style";


const DatePicker = ({
  label,
  gridArea,
  placeholder,
  ...rest
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(rest);

  const addMonths = (date, months) => {
    const newDate = date.getDate();

    date.setMonth(date.getMonth() + months);

    if (date.getDate() != newDate) {
      date.setDate(0);
    };

    return date;
  };

  return (
    <StyledFormControl
      gridArea={ gridArea }
    >
      <StyledLabel htmlFor={ field.name }>{ label }</StyledLabel>
      <StyledField
        id={ field.name }
        { ...field }
        { ...rest }
        selected={ (field.value && new Date(field.value)) || null }
        onChange={ val => setFieldValue(field.name, val) }
        minDate={ new Date() }
        maxDate={ addMonths(new Date(), 1) }
        showDisabledMonthNavigation
        placeholderText={ placeholder }
      />
      <ErrorMessage component={ TextError } name={ field.name } />
    </StyledFormControl>
  )
}


export default DatePicker;
