import styled, { keyframes } from "styled-components";


const ticker = ( scrollLength ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;

export const TickerWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
`;

export const StyledTicker = styled.div`
  display: flex;
  box-sizing: content-box;
  animation: ${ props => ticker( (props.itemWidth * 16 + 1) * props.numItems ) } 60s linear infinite;
`;
