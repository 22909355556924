import * as React from "react";
import { ErrorMessage } from "formik";

import TextError from "../../Errors/TextError";

import {
  FieldContainer,
  StyledCheckbox,
  StyledField,
  StyledFormControl,
  StyledLabel
} from "./style";


const Boolean = ( props ) => {
  const {
    label,
    name,
    gridArea,
    value,
    ...rest
  } = props;

  return (
    <StyledFormControl
      gridArea={ gridArea }
    >
      <StyledLabel>
        <StyledField
          type="checkbox"
          name={ name }
          { ...rest }
        />
        <StyledCheckbox></StyledCheckbox>
        { value }
      </StyledLabel>
      <ErrorMessage component={ TextError } name={ name } />
    </StyledFormControl>
  )
}


export default Boolean;
