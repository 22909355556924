import styled from "styled-components";

import {
  COLORS,
  SCREENS
} from "../../../../theme/style-constants";


export const StyledFormControl = styled.div`
  grid-area: ${ props => props.gridArea };
`;

export const StyledLabel = styled.label`
  font-weight: 700;
`;

export const FieldContainer = styled.div`
  padding: 1rem 0 0;
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border: 1px solid ${ COLORS.border.orange };
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const StyledOptionLabel = styled.label`
  padding-left: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & ${ StyledInput }:checked ~ ${ StyledCheckbox } {
    background-color: ${ COLORS.background.orange };
  }

  & ${ StyledInput }:checked ~ ${ StyledCheckbox }:after {
    display: block;
  }

  & ${ StyledCheckbox }:after {
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
    border: solid ${ COLORS.background.light };
    border-width: 4px;
    border-radius: 50%;
    transform: rotate(45deg);
  }
`;

export const OptionContainer = styled.div`
  display: block;
  position: relative;
  margin-right: 2rem;
  margin-bottom: 1rem;

  @media (min-width: ${ SCREENS.laptop }) {
    display: inline-block;
  }
`;
