import * as React from "react";

import {
  Container,
  StyledDescription,
  StyledHeading,
  StyledSection
} from "./style";


const PageHero = ({
  pageTitle,
  pageDescription,
  isComponentFullLength,
  Component
}) => {
  return (
    <StyledSection>
      <Container>
        <StyledHeading>
          { pageTitle }
        </StyledHeading>
        <StyledDescription
          margin={ !isComponentFullLength }
        >
          { pageDescription }
        </StyledDescription>
        { !isComponentFullLength &&
          <>
            { Component }
          </>
        }
      </Container>
      { isComponentFullLength &&
        <>
          { Component }
        </>
      }
    </StyledSection>
  )
}


export default PageHero;