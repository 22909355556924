import styled from "styled-components";

import {
  COLORS
} from "../../../../theme/style-constants";


export const StyledError = styled.div`
  margin: 0.5rem 0;
  color: ${ COLORS.text.error };
`;
