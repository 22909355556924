import * as React from "react";

import {
  StyledArrow,
  StyledButton,
  StyledButtonText,
  StyledIcon,
  StyledLine
} from "./style";


const SubmitButton = ({
  buttonText,
  darkBackground
}) => {
  return (
    <StyledButton
      type="submit"
      darkBackground={ darkBackground }
    >
      <StyledButtonText>{ buttonText }</StyledButtonText>
      <StyledIcon>
        <StyledLine
          darkBackground={ darkBackground }
        />
        <StyledArrow
          darkBackground={ darkBackground }
        />
      </StyledIcon>
    </StyledButton>
  )
}


export default SubmitButton;
